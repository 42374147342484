import React from "react"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const QuoteSlider = ({ quotes, infinite, adaptiveHeight, children }) => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: infinite,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: 10,
    variableWidth: true,
    adaptiveHeight: adaptiveHeight,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false
        }
      }
    ]
  }

  return (
    <div className="quote-slider__container">
      <Slider {...settings}>{children}</Slider>
    </div>
  )
}

export default QuoteSlider
