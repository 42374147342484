import React from "react"
import { graphql } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/pro-solid-svg-icons"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Section, Container } from "../components/Containers"
import Text from "../components/Text"
import { Button, ButtonGroup } from "../components/Buttons"
import Image from "../components/Image"
import { ImageText } from "../components/ImageText"
import TheaterVideo from "../components/Theater/TheaterVideo"
import { QuoteSlider, QuoteCard } from "../components/QuoteSlider"
import Sizzle from "../videos/homepage-sizzle.mp4"
import Form from "../components/Form"

const Homepage = ({ pageContext, data }) => {
  const { metaTitle, metaDescription, socialImage, schemaPage } = pageContext
  const {
    imageText,
    pageBlurb,
    pageBlurbTwo,
    imageTextRows,
    videoBlock,
    powerList,
    whatYouGet,
    platform,
    slider,
    cta
  } = data.allUniquePagesJson.nodes[0]

  const clickTheater = () => {
    const theaterButton = document.querySelector(".hero-video")
    theaterButton.click()
  }

  const youtubeIds = [imageText.buttons[0].button.youtube]
  return (
    <Layout className="homepage">
      <SEO
        title={metaTitle}
        description={metaDescription}
        socialImage={socialImage}
        schemaPage={schemaPage}
        videoIds={youtubeIds}
      />

      <Section>
        <Container>
          <div className="homepage__hero">
            <div className="img-txt homepage__hero-img-txt">
              <div className="img-txt__text">
                <Text text={imageText.text} />
                {/* {imageText.buttons && (
                  <ButtonGroup>
                    {imageText.buttons.map(btn => (
                      <Button
                        key={btn.button.href}
                        appearance={btn.button.appearance}
                        destination={btn.button.destination}
                        href={btn.button.href}
                        buttonText={btn.button.buttonText}
                        youtube={btn.button.youtube}
                        className={btn.button.youtube ? "hero-video" : ""}
                      />
                    ))}
                  </ButtonGroup>
                )} */}
              </div>
              <div className="img-txt__image">
                <div
                  // role="button"
                  // tabIndex="0"
                  // aria-label="open video player"
                  // onClick={clickTheater}
                  className="homepage__sizzle-container">
                  {/* <FontAwesomeIcon
                    color="#fff"
                    icon={faPlay}
                    className="homepage__sizzle-icon"
                  /> */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `
                      <video 
                        autoplay
                        loop
                        muted
                        playsinline
                        width="100%"
                      >
                        <source src="${Sizzle}" type="video/mp4" />
                      </video>`
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section large className="homepage__highlight-blurb">
        <Container maxWidth={700}>
          <Text
            role="heading"
            as="p"
            className="has-text-centered"
            text={pageBlurb}
          />
        </Container>
      </Section>

      {imageTextRows.map((item, i) => (
        <Section key={i} large>
          <Container>
            <ImageText
              className="homepage__img-txt"
              text={item.imageText.text}
              image={item.imageText.image}
              reverse={item.imageText.reverse}
              buttons={item.imageText.buttons}
            />
          </Container>
        </Section>
      ))}

      <Section large>
        <Container>
          <h2 className="has-text-centered">{powerList.heading}</h2>
          <div className="grid col-4 col-2--tablet col-1--mobile">
            {powerList.cards.map((card, i) => (
              <div className="pw-card" key={i}>
                <div className={`pw-card--header w-blue-${i + 1}`}>
                  <h3>{card.heading}</h3>
                </div>

                <p>{card.blurb}</p>
              </div>
            ))}
          </div>
        </Container>
      </Section>

      <Section large>
        <Container>
          <Text
            as="h2"
            className="has-text-centered"
            text={whatYouGet.heading}
          />
          <div className="wyg-card-container">
            {whatYouGet.cards.map((card, i) => (
              <div className={`wyg-card card-${i + 1}`} key={i}>
                <h3>{card.heading}</h3>
                <p>{card.blurb}</p>
              </div>
            ))}
          </div>
        </Container>
      </Section>

      {/* <Section colorBack>
        <Container maxWidth={800}>
          <Text
            as="h2"
            className="has-text-centered"
            text={videoBlock.heading}
          />
        </Container>
        <Container small>
          <TheaterVideo
            videoUrl={`https://www.youtube.com/watch?v=${videoBlock.youtube}`}
            controls
            playing
            overVideo
            iconOnly
            language={"en"}
          >
            <Image publicId={videoBlock.coverImage} />
          </TheaterVideo>
        </Container>
      </Section> */}

      <Section className="homepage__quotes">
        <Container maxWidth={620}>
          <Text
            as="h2"
            className="has-text-centered mb-3"
            text={slider.heading}
          />
          <Text
            as="p"
            className="has-text-centered mb-3"
            style={{ color: "#003a6e" }}
            text={slider.blurb}
          />
        </Container>
        <div className="side-fade">
          <QuoteSlider infinite>
            {slider.slides.map((slide, i) => (
              <Image
                className="home-slide-image"
                key={i}
                publicId={slide.image}
              />
            ))}
          </QuoteSlider>
        </div>
      </Section>

      <Section large className="homepage__outperform">
        <Container>
          <div className="grid col-2 col-1--mobile">
            <h2>{platform.heading}</h2>

            <ul className="themed-list">
              {platform.list.map(itm => (
                <li key={itm}>{itm}</li>
              ))}
            </ul>
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <div className="homepage__cta">
            <div className="homepage__cta-content">
              <Text as="h2" text={cta.heading} />
              <Text as="h3" text={cta.formHeading} />
            </div>

            <div className="homepage__cta-form">
              <Form src={cta.formUrl} />
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export const homepageQuery = graphql`
  query homepagequery {
    allUniquePagesJson(filter: { title: { eq: "/" } }) {
      nodes {
        imageText {
          text
          image
          buttons {
            button {
              buttonText
              appearance
              destination
              href
              youtube
            }
          }
        }
        pageBlurb
        pageBlurbTwo
        imageTextRows {
          imageText {
            reverse
            text
            image
          }
        }
        videoBlock {
          heading
          coverImage
          youtube
        }
        powerList {
          heading
          cards {
            heading
            blurb
          }
        }
        whatYouGet {
          heading
          cards {
            heading
            blurb
          }
        }
        slider {
          heading
          blurb
          slides {
            image
          }
        }
        platform {
          heading
          list
        }
        cta {
          heading
          formHeading
          formUrl
        }
      }
    }
  }
`

export default Homepage
